import React from "react";
import styled from "styled-components";

export interface ButtonSecondaryProps {
  onClick: any;
  text: string;
  disabled?: boolean;
}

export const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({ onClick, text, disabled = false }) => {
  return <BaseButton type="submit" onClick={onClick} disabled={disabled} >{text}</BaseButton>;
};

const BaseButton = styled.button`
  color: #FFFFFF;
  background: #1e90ff;
  border: 2px solid #0000ff;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  padding: 5px 15px;
  width: 160px;
  font-size: 18px;
  :disabled {
    border: 2px solid #696969;
    background: #9BA4B4;
    pointer-events: none;
  }
  &:hover {
    color: #1e90ff;
    background-color: #ffffff;
  }
`;

export default ButtonSecondary
