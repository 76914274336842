import React from 'react'
import styled from 'styled-components'

const Plan = () => {
  return (
    <PlanArea>
      <TextArea>
        <Content>
          <Title>料金プラン</Title>
        </Content>
      </TextArea>
      <PlanTable>
        <tr>
          <Head></Head>
          <OneMonthPriceHead>月間プラン</OneMonthPriceHead>
          <SixMonthPriceHead>半年間プラン</SixMonthPriceHead>
          <YearPriceHead>年間プラン</YearPriceHead>
        </tr>
        <tr>
          <TopBoby>価格</TopBoby>
          <OneMonthPriceBody>30万円</OneMonthPriceBody>
          <SixMonthPriceBody>
            <SaleText>約17%お得！</SaleText>
            <div>150万円</div>
          </SixMonthPriceBody>
          <YearPriceBody>
            <MostSaleText>約34%お得！</MostSaleText>
            <div>240万円</div>
          </YearPriceBody>
        </tr>
        <tr>
          <TopBoby>作成可能アカウント数</TopBoby>
          <Body>無制限</Body>
          <Body>無制限</Body>
          <YearBody>無制限</YearBody>
        </tr>
        <tr>
          <TopBoby>CSVダウンロード</TopBoby>
          <Body>利用可</Body>
          <Body>利用可</Body>
          <YearBody>利用可</YearBody>
        </tr>
        <tr>
          <TopBoby>各種申請</TopBoby>
          <Body>利用可</Body>
          <Body>利用可</Body>
          <YearBody>利用可</YearBody>
        </tr>
        <tr>
          <TopBoby>管理者アラート機能</TopBoby>
          <Body>利用可</Body>
          <Body>利用可</Body>
          <YearBody>利用可</YearBody>
        </tr>
        <tr>
          <TopBoby>オプション</TopBoby>
          <Body>別途相談可</Body>
          <Body>別途相談可</Body>
          <YearBody>別途相談可</YearBody>
        </tr>
      </PlanTable>
      <Description>
        ※上記プランは税抜価格です<br />
        ※当社サービスは自動更新となっています
      </Description>
      <Options>
        <TextArea>
          <Content>
            <Title>オプション表</Title>
          </Content>
        </TextArea>
        <OptionTableArea>
          <OptionTable>
            <thead>
              <tr>
                <OptionTableHead>オプション名</OptionTableHead>
                <OptionTableHead>価格</OptionTableHead>
              </tr>
            </thead>
            <tbody>
              <OptionTableTr>
                <OptionTableTd>導入及びサポート</OptionTableTd>
                <OptionTableTd>55万円〜</OptionTableTd>
              </OptionTableTr>
              <OptionTableTr>
                <OptionTableTd>DXコンサルティング</OptionTableTd>
                <OptionTableTd>45万円〜</OptionTableTd>
              </OptionTableTr>
              <OptionTableTr>
                <OptionTableTd>マニュアル作成</OptionTableTd>
                <OptionTableTd>20万円〜</OptionTableTd>
              </OptionTableTr>
            </tbody>
          </OptionTable>
        </OptionTableArea>
        <Description>
          ※上記は税抜価格です
        </Description>
      </Options>
    </PlanArea>
  )
}

const PlanArea = styled.div`
  background: linear-gradient(90deg, rgba(26, 7, 78, 1), rgba(1, 2, 7, 1) 10%, rgba(1, 2, 7, 1) 50%, rgba(1, 2, 7, 1) 90%, rgba(26, 7, 78, 1));
  padding: 50px 150px;
  margin: 100px 0;
`
const TextArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3rem 0;
`
const Content = styled.div`
  width: fit-content;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 1rem;
  box-shadow: 0 0 1rem #ffffff;
  padding: 1rem 3rem;
  text-align: center;
  font-size: 2rem;
  color: #000000;
`
const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #14274E;
  text-align: center;
`
const PlanTable = styled.table`
  background: #ffffff;
  margin: 0 auto;
  padding: 4rem;
  width: 80%;
  border-collapse: separate;
  text-align: center;
`
const Head = styled.th`
  color: #ffffff;
  padding: 20px 30px;
`
const OneMonthPriceHead = styled(Head)`
  color: #707071;
  background: #ffffff;
  font-size: 20px;
`
const SixMonthPriceHead = styled(Head)`
  background: #394867;
  font-size: 20px;
`
const YearPriceHead = styled(Head)`
  background: #6C63FF;
  font-size: 24px;
`
const Body = styled.td`
  padding:  1rem;
`
const TopBoby = styled(Body)`
  color: #707071;
  font-size: 18px;
  text-align: left;
`
const OneMonthPriceBody = styled(Body)`
  color: #707071;
  font-size: 20px;
  font-weight: bold;
`
const SixMonthPriceBody = styled(Body)`
  font-size: 20px;
  font-weight: bold;
`
const YearPriceBody = styled(Body)`
  font-size: 30px;
  font-weight: bold;
`
const YearBody = styled(Body)`
  font-size: 20px;
  font-weight: bold;
`
const Description = styled.p`
  color: #707071;
  font-size: 12px;
  margin: 10px 0 0;
  padding-left: 10rem;
  display: none;
`
const Options = styled.div`
  margin: 0 auto;
  padding: 4rem;
  width: 60%;
`
const OptionTableArea = styled.div`
  width: 80%;
  margin: auto;
  padding: 4rem 0;
  background: #ffffff;
`
const OptionTable = styled.table`
  width: 80%;
  margin: auto;
  border-collapse: separate;
`
const OptionTableHead = styled.th`
  font-size: 1.2rem;
  text-align: left;
  border-bottom: 1px solid #000000;
`
const OptionTableTr = styled.tr`
`
const OptionTableTd = styled.td`
  padding: 1rem 0;
  font-size: 1.5rem;
`
const Item = styled.div`
  background: #ffffff;
  display: table;
  text-align: center;
  width: 280px;
  height: 145px;
  border-radius: 30px;
  box-shadow: 0 0 1rem 0;
`
const ItemText = styled.p`
  display: table-cell;
  font-weight: bold;
  vertical-align: middle;
`
const SaleText = styled.div`
  font-size: 1rem;
  color: #ffa500;
`
const MostSaleText = styled.div`
  font-size: 1rem;
  color: #ff69b4;
  animation-name: cloud;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1.5s;

  @keyframes cloud {
    0% {
      transform: translate(0, 0px);
    }
    100% {
      transform: translate(0, -5px);
    }
  }
`
export default Plan
