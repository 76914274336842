// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

const images = require.context("../images", true)
const imagePath = image_name => images(image_name, true)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Corporate Logo";
    src: url("../fonts/Corporate-Logo-Medium-ver3.otf") format('opentype');
    font-style: normal;
    font-weight: normal;
  }
  @font-face {
    font-family: "Pirulen";
    src: url("../fonts/pirulen.ttf") format('truetype');
    font-style: normal;
    font-weight: normal;
  }
`;

export default GlobalStyle;