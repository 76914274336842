import React from 'react'
import styled from 'styled-components'
import total from 'images/home/feature/management_total.png'
import stafflist from 'images/home/feature/management_stafflist.png'
import managementRequestlist from 'images/home/feature/management_requestlist.png'
import csv from 'images/home/feature/management_csv.png'
import staffRequestlist from 'images/home/feature/staff_requestlist.png'
import timestamp from 'images/home/feature/staff_timestamp.png'

export const Feature = () => {
  return (
    <FeatureArea>
      <Title>機能一覧</Title>
      <List>
        <Item>
          <SubTitle>管理者機能</SubTitle>
          <Content>
            <ImgArea>
              <Img src={stafflist} />
            </ImgArea>
            <TextArea>
              <ContentTitle>従業員管理機能</ContentTitle>
              <ContentText>
                全職種の社員を一覧で登録することができます。部署や管轄、所属店舗等のグループ登録や、社員種別（正社員、契約社員、業務委託等）も社内カスタマイズ登録できるので、
                一目で社員の属性が把握できます。
              </ContentText>
            </TextArea>
          </Content>
          <Content>
            <TextArea>
              <ContentTitle>各種申請管理機能</ContentTitle>
              <ContentText>
                全社員から提出された休暇申請、休日出勤申請といった各種申請を一覧で登録することができます。申請書を印刷して、手書きで記入し、捺印して提出といったアナログ業務フローから脱却でき、
                管理者、社員双方の業務効率改善をサポートします。
              </ContentText>
            </TextArea>
            <ImgArea>
              <Img src={managementRequestlist} />
            </ImgArea>
          </Content>
          <Content>
            <ImgArea>
              <Img src={csv} />
            </ImgArea>
            <TextArea>
              <ContentTitle>CSVダウンロード機能</ContentTitle>
              <ContentText>
                全社員の勤怠情報は自動集計され、システム上で表示されますが、さらに詳細に集計したい。個別にまとめたい等の作業用に指定した条件での勤怠情報のデータ出力が
                CSV形式で可能です。
              </ContentText>
            </TextArea>
          </Content>
        </Item>
        <Item>
          <SubTitle>社員機能</SubTitle>
          <Content>
            <TextArea>
              <ContentTitle>打刻機能</ContentTitle>
              <ContentText>
                ワンクリックで出勤→退勤ができ、日報やTODOメモも同時に記入登録することができます。また、退勤の押し忘れ等があっても打刻の修正ができますので、ご安心ください。
              </ContentText>
            </TextArea>
            <ImgArea>
              <Img src={timestamp} />
            </ImgArea>
          </Content>
          <Content>
            <ImgArea>
              <Img src={staffRequestlist} />
            </ImgArea>
            <TextArea>
              <ContentTitle>各種申請機能</ContentTitle>
              <ContentText>
                休暇申請、残業申請、休日出勤申請等を最低限の情報入力で管理者に対してシステム上で申請提出ができます。
                申請後、リアルタイムに管理者側に申請が届くので、承認待ちへの時間も短縮されます。
              </ContentText>
            </TextArea>
          </Content>
        </Item>
      </List>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="11.833" height="46.876" viewBox="0 0 11.833 46.876">
        <g id="グループ_4" data-name="グループ 4" transform="translate(-934 -1308.955)">
          <path id="パス_78" data-name="パス 78" d="M21.114,38.969a2.451,2.451,0,0,1-4.529,1.3L10,33.682a2.451,2.451,0,1,1,3.468-3.465l2.745,2.746v-18.5a2.451,2.451,0,1,1,4.9,0V38.967Z" transform="translate(924.719 1296.945)" fill="#9ba4b4" />
          <path id="パス_77" data-name="パス 77" d="M19.476,8.441A2.451,2.451,0,0,1,24,7.141l6.587,6.586a2.451,2.451,0,0,1-3.468,3.465l-2.745-2.746v18.5a2.451,2.451,0,1,1-4.9,0V8.441Z" transform="translate(914.524 1320.432)" fill="#14274e" />
        </g>
      </svg> */}
    </FeatureArea>
  )
}

const FeatureArea = styled.div`
  margin-bottom: 3rem;
  text-align: center;
`
const Title = styled.div`
  width: 70%;
  font-size: 2rem;
  font-weight: bold;
  color: #000000;
  border-bottom: 2px solid #000000;
  margin: 100px auto 33px;
`
const SubTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #000000;
`
const List = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 1rem;
`
const Item = styled.div`
  padding: 2rem;
  border: 1px solid #000000;
  border-radius: 1rem;
`
const Content = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
`
const ContentTitle = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  border-bottom: 1px solid gray;
  text-align: left;
`
const ContentText = styled.p`
  text-align: left;
`
const ImgArea = styled.div`
  width: 50%;
`
const Img = styled.img`
  width: 100%;
  height: auto;
`
const TextArea = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`

export default Feature
