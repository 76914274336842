import React from 'react'
import styled from 'styled-components'
import TopBanner from 'images/home/top_banner.jpg'

export const Top = () => {
  return (
    <TopArea>
      <Img src={TopBanner} />
      <Title>
        <span>ALL</span>
        <span>IN</span>
        <span>WORKS</span>
      </Title>
      <SubContent>
        <span>勤怠管理業務DX。ストレスフリーな管理体制へフルサポート</span>
      </SubContent>
    </TopArea>
  )
}

const TopArea = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`
const Img = styled.img`
  width: 100%;
`
const Title = styled.h1`
  font-family: 'Pirulen';
  font-size: 3.5rem;
  color: transparent;
  position: absolute;
  top: 20rem;
  left: 10rem;
  display: flex;
  gap: 1.5rem;

  span {
    animation: blur 5s ease-out infinite;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  span:nth-child(1) {
    animation-delay: 0.1s;
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.3s;
  }

  @keyframes blur {
    0%    {text-shadow:  0 0 100px #fff; opacity:0;}
    5%    {text-shadow:  0 0 90px #fff;}
    15%   {opacity: 1;}
    20%   {text-shadow:  0 0 0px #fff;}
    80%   {text-shadow:  0 0 0px #fff;}
    85%   {opacity: 1;}
    95%   {}
    100%  {color: #ffffff}
  }
`
const SubContent = styled.h2`
  font-size: 2rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: absolute;
  top: 40rem;
  right: 10rem;
  color: transparent;

  span {
    animation: blur 5s ease-out infinite;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-delay: 1s;

  }

  @keyframes blur {
    0%    {text-shadow:  0 0 100px #fff; opacity:0;}
    5%    {text-shadow:  0 0 90px #fff;}
    15%   {opacity: 1;}
    20%   {text-shadow:  0 0 0px #fff;}
    80%   {text-shadow:  0 0 0px #fff;}
    85%   {opacity: 1;}
    95%   {}
    100%  {color: #ffffff}
  }
`
export default Top
