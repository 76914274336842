import React from 'react'
import styled from 'styled-components'

export const LoginButton = () => {
  return (
    <Login href='/companies/sign_in'>
      ログイン
    </Login>
  )
}

const Login = styled.a`
  color: #0000ff;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1rem;
  &:hover {
    color: #0000ff;
    background-color: rgba(255, 255, 255, 0.9);
  }
`

export default LoginButton
