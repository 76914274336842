import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import Form from '../../../organisms/management/break_times/Form'

export const Index = () => {
  return (
    <IndexArea>
      <TitleArea>
        <Title value='自動休憩設定' />
      </TitleArea>
      <Form />
    </IndexArea>
  )
}

const IndexArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 0 auto;
  padding: 1rem 2rem 1rem 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 1rem;
`
const TitleArea = styled.div`
  width: 100%;
  text-align: center;
`

export default Index
