import React from "react";
import styled from "styled-components";

export interface SubmitButtonProps {
  onClick: any;
  text: string;
  disabled?: boolean;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({ onClick, text, disabled = false }) => {
  return <BaseButton type="submit" onClick={onClick} disabled={disabled} >{text}</BaseButton>;
};

const BaseButton = styled.button`
  color: #ffffff;
  background: #008000;
  border: 2px solid #006400;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  padding: 5px 15px;
  width: fit-content;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  &:disabled {
    color: #000000;
    background: #9BA4B4;
    border: 2px solid #696969;
    pointer-events: none;
  }
  &:hover {
    color: #008000;
    background-color:transparent;
  }
`;

export default SubmitButton
