import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import SubmitButton from '../../../atoms/SubmitButton'
import Info from '../../../atoms/share/Info'
import Error from '../../../atoms/share/Error'
import { years, months, days } from '../../../../modules/date'
import { fetchEmployees } from '../../../../api/employee'
import { fetchDepartments } from '../../../../api/department'
import { fetchEmploymentStatuses } from '../../../../api/employment_status'

export const Form = () => {
  type Properties = {
    id: string, name: string
  }
  const [startYear, setStartYear] = useState(null);
  const [startMonth, setStartMonth] = useState(null);
  const [startDay, setStartDay] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const [endMonth, setEndMonth] = useState(null);
  const [endDay, setEndDay] = useState(null);
  const [department, setDepartment] = useState<Properties>();
  const [employmentStatus, setEmploymentStatus] = useState<Properties>();
  const [name, setName] = useState<Properties>();
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState<Properties[]>([]);
  const [employmentStatuses, setEmploymentStatuses] = useState<Properties[]>([]);
  const [names, setNames] = useState<Properties[]>([]);
  const [info, setInfo] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const now = new Date
    setEndYear({label: now.getFullYear(), value: now.getFullYear()})
    setEndMonth({label: now.getMonth()+1, value: now.getMonth()+1})
    setEndDay({label: now.getDate(), value: now.getDate()})
    fetchDepartments()
      .then((res) => { setDepartments(res.data) })
    fetchEmploymentStatuses()
      .then((res) => { setEmploymentStatuses(res.data) })
    fetchEmployees()
      .then((res) => {
        setNames(res.data)
      })
  }, []);

  const handleSubmit = async () => {
    setInfo('')
    setError('')
    setLoading(true)
    try {
      const download = async () => {
        const params =
          `start_year=${startYear.value}` +
          `&start_month=${startMonth.value}` +
          `&start_day=${startDay.value}` +
          `&end_year=${endYear.value}` +
          `&end_month=${endMonth.value}` +
          `&end_day=${endDay.value}` +
          `&department_id=${department !== undefined ? department.id : ''}` +
          `&employment_status_id=${employmentStatus !== undefined ? employmentStatus.id : ''}` +
          `&id=${name !== undefined ? name.id : ''}`;
        document.location.href = `/management/csv_download/download?${params}`;
      }
      await download()
      setInfo('ダウンロードしました。')
    } catch (e) {
      setError('ダウンロードに失敗しました。')
    }
    setLoading(false)
  }

  const infoMessage = () => {
    if (info !== "") {
      return <Info>{info}</Info>
    }
  }

  const errorMessage = () => {
    if (error !== "") {
      return <Error>{error}</Error>
    }
  }

  return (
    <FormArea>
      <Notice>{infoMessage()}{errorMessage()}</Notice>
      <FormBody>
        <Item>
          <FormLabel>日付</FormLabel>
          <SelectBox><Select value={startYear} onChange={setStartYear} options={years} />年</SelectBox>
          <SelectBox><Select value={startMonth} onChange={setStartMonth} options={months} />月</SelectBox>
          <SelectBox><Select value={startDay} onChange={setStartDay} options={days} />日</SelectBox>
          <Space>〜</Space>
          <SelectBox><Select value={endYear} onChange={setEndYear} options={years} />年</SelectBox>
          <SelectBox><Select value={endMonth} onChange={setEndMonth} options={months} />月</SelectBox>
          <SelectBox><Select value={endDay} onChange={setEndDay} options={days} />日</SelectBox>
        </Item>
        <Item>
          <FormLabel>所属グループ</FormLabel>
          <SelectBox>
            <Select
              value={department}
              onChange={setDepartment}
              options={departments}
              getOptionLabel={(option: Properties) => option.name}
              getOptionValue={(option: Properties) => option.id}
              styles={SelectArea}
              placeholder="選択してください"
            />
          </SelectBox>
        </Item>
        <Item>
          <FormLabel>従業員種別</FormLabel>
          <SelectBox>
            <Select
              value={employmentStatus}
              onChange={setEmploymentStatus}
              options={employmentStatuses}
              getOptionLabel={(option: Properties) => option.name}
              getOptionValue={(option: Properties) => option.id}
              styles={SelectArea}
              placeholder="選択してください"
            />
          </SelectBox>
        </Item>
        <Item>
          <FormLabel>名前</FormLabel>
          <SelectBox>
            <Select
              value={name}
              onChange={setName}
              options={names}
              getOptionLabel={(option: Properties) => option.name}
              getOptionValue={(option: Properties) => option.id}
              styles={SelectArea}
              placeholder="選択してください"
            />
          </SelectBox>
        </Item>
      </FormBody>
      <ButtonBox>
        <SubmitButton
          text="ダウンロード"
          onClick={handleSubmit}
          disabled={
            loading ||
            !startYear ||
            !startMonth ||
            !startDay ||
            !endYear ||
            !endMonth ||
            !endDay
          }
        />
      </ButtonBox>
    </FormArea>
  )
}

const FormArea = styled.div`
`
const FormBody = styled.form`
  font-size: 16px;
`
const Notice = styled.div`
  text-align: center;
`
const Item = styled.div`
  display: flex;
  list-style: none;
  margin-bottom: 24px;
`
const FormLabel = styled.label`
  color: #000000;
  text-align: right;
  width: 15%;
  margin: auto 3rem auto;
`
const SelectBox = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0 5px;
`
const SelectArea = {
  control: (provided) => ({
    ...provided,
    width: '15rem',
  }),
};

const Space = styled.div`
  display: flex;
  align-items: center;
  margin: 0 5px;
`
const ButtonBox = styled.div`
  width: 100%;
  margin: 75px auto 0;
  text-align: center;
`

export default Form
