import React from 'react';
import styled from 'styled-components'

export interface HeaderProps {
  companyName: string;
}

export const Header: React.FC<HeaderProps> = ({ companyName }) => {
  return (
    <HeaderArea>
      <Title>
        管理者アカウント
      </Title>
      <Company>
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 20">
          <path d="M5,3v18h6v-3.5h2V21h6V3H5 M7,5h2v2H7V5 M11,5h2v2h-2V5 M15,5h2v2h-2V5 M7,9h2v2H7V9 M11,9h2v2h-2V9 M15,9h2v2h-2V9 M7,13h2 v2H7V13 M11,13h2v2h-2V13 M15,13h2v2h-2V13 M7,17h2v2H7V17 M15,17h2v2h-2V17z" fill="#000000" />
        </svg>
        <CompanyName>{companyName}</CompanyName>
      </Company>
    </HeaderArea>
  )
}

const HeaderArea = styled.div`
  width: 98%;
  display: flex;
  margin-bottom: 100px;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.7);
  padding: 1rem;
  border-radius: 1rem;
`
const Title = styled.div`
  color: #000000;
  font-weight: bold;
  font-size: 1.5rem;
  margin: auto 0;
`
const Company = styled.div`
  display: flex;
  margin: 0 5rem 0 0;
  align-items: right;
`
const CompanyName = styled.div`
  display: flex;
  align-items: center;
  padding-top: 4px;
`

export default Header
