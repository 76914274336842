import React from 'react'
import styled from 'styled-components'
import summary1 from 'images/home/summary/comment_01.png'
import summary2 from 'images/home/summary/comment_02.png'
import summary3 from 'images/home/summary/comment_03.png'

export const Summary = () => {
  return (
    <SummaryArea>
      <TextArea>
        <Content>
          <Name>ALL IN WORKS</Name>の特徴
        </Content>
      </TextArea>
      <List>
        <Item1Area>
          <Img src={summary1} />
        </Item1Area>
        <Item2Area>
          <Img src={summary2} />
        </Item2Area>
        <Item1Area>
          <Img src={summary3} />
        </Item1Area>
      </List>
    </SummaryArea>
  )
}

const SummaryArea = styled.div`
  background: linear-gradient(90deg, rgba(26, 7, 78, 1), rgba(1, 2, 7, 1) 10%, rgba(1, 2, 7, 1) 50%, rgba(1, 2, 7, 1) 90%, rgba(26, 7, 78, 1));
  padding-bottom: 3rem;

`
const TextArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3rem 0;
`
const Content = styled.div`
  width: fit-content;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 1rem;
  box-shadow: 0 0 1rem #ffffff;
  padding: 1rem 3rem;
  text-align: center;
  font-size: 2rem;
  color: #000000;
`
const Name = styled.span`
  font-family: 'Pirulen', sans-serif;
`
const List = styled.div`
  width: 70%;
  background-color: rgba(255, 255, 255, 0.7);
  margin: 0 auto;
  border-radius: 1rem;
  box-shadow: 0 0 1rem #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 3rem;
`
const Item1Area = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`
const Item2Area = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
`
const Img = styled.img`
  width: 50rem;
`

export default Summary
