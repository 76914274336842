import React from 'react';
import styled from 'styled-components'

export interface Props {
  path: string;
  text: string;
}

export const ButtonThirdly: React.FC<Props> = ({ path, text }) => {
  return <ButtonArea href={path}>{text}</ButtonArea>
}

const ButtonArea = styled.a`
  display: block;
  color: #ffffff;
  background: #8b008b;
  border: 1px solid #8a2be2;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 5px 15px;
  text-decoration: none;
  text-align: center;
  width: 5rem;
  white-space: nowrap;
  &:hover {
    color: #000000;
    background-color:transparent;
  }
`

export default ButtonThirdly
