import React from 'react'
import styled from 'styled-components'

export const SignupButton = () => {
  return (
    <Signup href='/companies/sign_up'>新規登録</Signup>
  )
}

const Signup = styled.a`
  color: #000000;
  cursor: pointer;
  font-size: 1.2rem;
  width: fit-content;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1rem;
  &:hover {
    color: #ffffff;
    background-color: rgba(105, 105, 105, 0.7);
  }
`

export default SignupButton
