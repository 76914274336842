import React from 'react';
import styled from 'styled-components'

export const SectionTitle: React.FC = ({ children }) => {
  return (
    <SectionTitleArea>
      <StatusArea workingStatus={String(children)}>{children}</StatusArea>
      <SectionMessage workingStatus={String(children)}>今日も1日お疲れ様でした！</SectionMessage>
    </SectionTitleArea>
  )
}

const SectionTitleArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`
const StatusArea = styled.div<{ workingStatus: string }>`
  width: 10%;
  border: 2px solid ${(props) => getBorderColor(props)};
  box-sizing: border-box;
  color: #000000;
  background-color: ${(props) => getBackgroundColor(props)};
  font-size: 16px;
  font-weight: bold;
  padding: 5px 10px;
  text-align: center;
`
const SectionMessage = styled.div<{ workingStatus: string }>`
  display: ${(props) => props.workingStatus === '退勤済' ? 'block' : 'none'};
`

function getBorderColor(props) {
  switch (props.workingStatus) {
    case '出勤中':
      return '#1e90ff';
      break;
    default:
      return '#9BA4B4';
      break;
  }
};
function getBackgroundColor(props) {
  switch (props.workingStatus) {
    case '出勤中':
      return '#00ffff';
      break;
    case '退勤済':
      return '#c0c0c0';
      break;
    default:
      return '#ffffff';
      break;
  }
};

export default SectionTitle