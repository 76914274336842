import React from 'react'
import styled from 'styled-components'
import Logo from 'images/share/logo.png'

// import { PrimaryButton } from 'smarthr-ui'
// <PrimaryButton>Hello World</PrimaryButton>

export const Footer = () => {
  return (
    <FooterArea>
      <Title>ALL IN WORKS</Title>
      <CompanyArea>
        <Middle href='https://monolith-j.com/' target={'_blank'} rel='no-referrer'>
          <Img src={Logo} />
        </Middle>
      </CompanyArea>
    </FooterArea>
  )
}

const FooterArea = styled.footer`
  height: 5rem;
  background-color:  rgba(150,152,154);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
`
const Title = styled.div`
  width: 50%;
  font-size: 2rem;
  font-weight: bold;
  margin: auto;
  font-family: 'Pirulen', sans-serif, 'Noto Sans JP';
`
const CompanyArea = styled.div`
  width: 50%;
  display: flex;
  justify-content: right;
  padding-right: 5rem;
`
const Middle = styled.a`
`
const Img = styled.img`
  width: 10rem;
`

export default Footer
