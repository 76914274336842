import React from 'react';
import styled from 'styled-components'
import WorkStatus from '../../organisms/management/WorkStatus'
import StaffList from '../../organisms/management/staff/StaffList'
import Title from '../../atoms/share/Title'
import ButtonPrimary from '../../atoms/share/button/ButtonPrimary';

export const Content = () => {
  return (
    <ContentArea>
      <Item>
        <Title value='勤務状況'></Title>
        <WorkStatus />
      </Item>
      <Item>
        <TitleArea>
          <Title value='従業員一覧'></Title>
          <ButtonPrimary text='新規登録' path='/management/staff/new' />
        </TitleArea>
        <StaffList />
      </Item>
    </ContentArea>
  )
}

const ContentArea = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`
const Item = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 2rem;
  border-radius: 1rem;
`
const TitleArea = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
`

export default Content
